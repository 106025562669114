<template>
  <div>
    <header class="navbar">
      <div class="menu-section">
        <a
          class="menu-item menu-home"
          :href="!isSignedIn ? 'https://cutr.com' : '/home'"
        >
          <div class="flex-hor-start">
            <svg height="32" viewBox="0 0 77 32">
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.974 25.471C10.808 25.471 6.52912 22.1836 6.52912 16.0783C6.52912 10.7819 9.92093 6.52912 15.9218 6.52912C18.5831 6.52912 21.3226 7.67713 23.3055 10.1036L20.5921 12.3735C19.3919 10.9124 17.8264 10.0253 15.9218 10.0253C12.7648 10.0253 10.3644 12.3735 10.3644 16.0783C10.3644 19.5224 12.6605 22.0009 15.974 22.0009C17.9047 22.0009 19.5224 21.0355 20.6442 19.6527L23.3316 21.9748C21.74 23.9577 19.2353 25.471 15.974 25.471ZM16.0001 0.03125C7.18072 0.03125 0.03125 7.18072 0.03125 16.0001C0.03125 24.8194 7.18072 31.9689 16.0001 31.9689C24.8194 31.9689 31.9689 24.8194 31.9689 16.0001C31.9689 7.18072 24.8194 0.03125 16.0001 0.03125Z"
                fill="#FFBE2E"
              />
              <path
                fill-rule="evenodd"
                clip-rule="evenodd"
                d="M15.974 25.471C10.808 25.471 6.52912 22.1836 6.52912 16.0783C6.52912 10.7819 9.92093 6.52912 15.9218 6.52912C18.5831 6.52912 21.3226 7.67713 23.3055 10.1036L20.5921 12.3735C19.3919 10.9124 17.8264 10.0253 15.9218 10.0253C12.7648 10.0253 10.3644 12.3735 10.3644 16.0783C10.3644 19.5224 12.6605 22.0009 15.974 22.0009C17.9047 22.0009 19.5224 21.0355 20.6442 19.6527L23.3316 21.9748C21.74 23.9577 19.2353 25.471 15.974 25.471ZM16.0001 0.03125C7.18072 0.03125 0.03125 7.18072 0.03125 16.0001C0.03125 24.8194 7.18072 31.9689 16.0001 31.9689C24.8194 31.9689 31.9689 24.8194 31.9689 16.0001C31.9689 7.18072 24.8194 0.03125 16.0001 0.03125Z"
                fill="#FFBE2E"
              />
              <path
                d="M47.434 22.108C49.684 22.108 51.376 21.064 52.474 19.696L50.872 18.328C50.062 19.336 48.874 20.074 47.434 20.074C45.004 20.074 43.258 18.274 43.258 15.646C43.258 12.946 44.968 11.128 47.398 11.128C48.748 11.128 49.882 11.704 50.836 12.874L52.438 11.524C51.106 9.886 49.27 9.076 47.398 9.076C43.294 9.076 40.99 12.01 40.99 15.646C40.99 19.84 43.96 22.108 47.434 22.108ZM57.5413 22.144C58.5493 22.144 59.5213 21.73 60.2233 20.884L60.2593 20.902V22H62.2753V13.216H60.2593V17.428C60.2593 19.318 59.1793 20.308 58.0093 20.308C56.8033 20.308 56.2633 19.768 56.2633 18.022V13.216H54.2473V18.562C54.2473 21.172 55.5973 22.144 57.5413 22.144ZM67.4435 22.144C68.5955 22.144 69.1535 21.91 69.6755 21.622L69.1535 19.876C68.6855 20.128 68.1995 20.254 67.8575 20.254C67.2815 20.254 67.0115 19.93 67.0115 19.03V14.926H69.5855V13.216H67.0115V10.084L64.9955 10.3V13.216H63.9155V14.926H64.9955V19.228C64.9955 21.19 65.9315 22.144 67.4435 22.144ZM71.4078 22H73.4238V17.842C73.4238 16.402 74.2878 15.304 75.8178 15.304C75.9618 15.304 76.1958 15.322 76.4298 15.34V13.09H76.1778C75.1158 13.09 74.1438 13.612 73.4598 14.53H73.4238V13.216H71.4078V22Z"
                fill="#101828"
              />
            </svg>
          </div>
        </a>
      </div>

      <template v-if="!$route.meta?.whiteLabel">
        <div
          class="menu-section"
          v-if="
            store.canSwitch &&
            ['Customer', 'Maker'].includes(store.activeSessionType)
          "
        >
          <div class="menu-item">
            <Badge :class="store.activeSessionType" variant="pill">{{
              $t(`auth:orgTypes.${store.activeSessionType.toLowerCase()}`)
            }}</Badge>
          </div>
        </div>
        <div class="menu-section flexAlign" style="gap: var(--navItemGap)">
          <router-link
            v-for="(link, index) in navLinksForUser"
            :key="index"
            :to="{ name: link.name }"
            class="menu-item"
          >
            {{ $t(`navigation:menu.${link.title}`) }}
          </router-link>
        </div>
        <div class="menu-section" v-if="store.isAdmin">
          <div
            class="menu-item"
            :style="
              isAdministrationPage
                ? 'box-shadow: inset 0px -2px 0px 0px var(--gray-9)'
                : ''
            "
          >
            <VMenu class="admin-menu">
              <a href="#" @click.prevent style="line-height: 2"
                >{{ 'Administration' }} <span data-i="arrow_drop_down"
              /></a>
              <template #popper="{ hide }">
                <menu class="btn-menu">
                  <li v-for="(link, index) in administrationLinks" :key="index">
                    <Button
                      variant="menu"
                      :to="{ name: link.name }"
                      @click="hide"
                      :class="{ active: link.name === route.name }"
                    >
                      {{ $t(`navigation:menu.${link.title}`) }}
                    </Button>
                  </li>
                </menu>
              </template>
            </VMenu>
          </div>
        </div>

        <div class="menu-section">
          <div class="menu-item">
            <template v-if="!isSignedIn">
              <Button
                class="white compact"
                v-show="$route.name !== 'signin'"
                @click="goToRoute('signin')"
              >
                {{ $t('navigation:menu.signIn') }}
              </Button>
              <Button
                class="white compact"
                v-show="$route.name === 'signin'"
                @click="goToRoute('signup')"
              >
                {{ $t('navigation:menu.signUp') }}
              </Button>
            </template>
            <MessagesBubble style="margin-right: 0.5rem" v-if="isSignedIn" />
            <DropdownMenu
              v-if="isSignedIn"
              :buttonsList="accountMenuLinks"
              triggerColor="white"
              triggerIcon="person"
              triggerClass="compact"
              triggerOnHover
              triggerDataCy="account-menu"
            />
          </div>
        </div>

        <div class="menu-section lng" v-show="!isSignedIn">
          <LangSelector class="menu-item" />
        </div>

        <div class="menu-section mobileMenu">
          <div class="menu-item">
            <template v-if="!isSignedIn">
              <Button
                class="white compact"
                v-show="$route.name !== 'signin'"
                @click="goToRoute('signin')"
              >
                {{ $t('navigation:menu.signIn') }}
              </Button>
              <Button
                class="white compact"
                v-show="$route.name === 'signin'"
                @click="goToRoute('signup')"
              >
                {{ $t('navigation:menu.signUp') }}
              </Button>
            </template>
            <Button
              color="borderless"
              class="compact"
              :icon="isOpen ? 'close' : 'menu'"
              @click="isOpen = !isOpen"
            />
          </div>
        </div>
      </template>
      <div v-if="$route.meta?.whiteLabel" style="margin-left: auto" />
    </header>

    <MobileMenu v-if="isOpen" :onClose="() => (isOpen = false)" />
  </div>
</template>

<script setup lang="ts">
import { computed } from 'vue';
import { useRoute, useRouter } from '@/composables/plugins';
import { useActiveUserStore } from '@/modules/store';
import { ref } from 'vue';
import { watchEffect } from 'vue';

import MobileMenu from '@/elements/Navigation/MobileMenu.vue';
import { useNavigation } from '@/elements/Navigation/composables';
import DropdownMenu from '../common/DropdownMenu.vue';
import LangSelector from '@/elements/Navigation/LangSelector.vue';
import MessagesBubble from '@/elements/Notifications/MessagesBubble.vue';

const store = useActiveUserStore();
const router = useRouter();
const route = useRoute();
const isOpen = ref(false);

const { navLinks, accountMenuLinks, isSignedIn, goToRoute } = useNavigation();

watchEffect(() => {
  document.body.style.overflow = isOpen.value ? 'hidden' : 'auto';
});

router.beforeEach((to, from, next) => {
  if (to.name !== from.name) isOpen.value = false;
  next();
});

const administrationLinks = navLinks.value.filter(
  ({ type }) => type === 'administration'
);
const navLinksForUser = computed(() =>
  navLinks.value.filter((n) => !(n.type === 'administration'))
);

const isAdministrationPage = computed(() =>
  administrationLinks.some((link) => link.name === route.name)
);
</script>

<style scoped>
.admin-menu {
  [data-i]:before {
    margin: 0;
  }
}
</style>
