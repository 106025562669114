export { default as Billing } from './Billing/index.vue';

export const AdminBillingCustomer = () =>
  import('./Billing/Admin/BillingCustomer.vue');
export const AdminBillingMaker = () =>
  import('./Billing/Admin/BillingMaker.vue');
export const AdminBillingOther = () =>
  import('./Billing/Admin/BillingOther.vue');

export const CutlistOrders = () => import('./CutlistOrders.vue');
export const CutlistOrder = () => import('./CutlistOrder.vue');
export const Files = () => import('./Files.vue');
export const Home = () => import('./Home.vue');
export const Materials = () => import('./Materials.vue');
export const NotFound = () => import('./NotFound.vue');
export const Order = () => import('./Order.vue');
export const PasswordRequest = () => import('./PasswordRequest.vue');
export const PasswordReset = () => import('./PasswordReset.vue');
export const RFQView = () => import('./Rfq.vue');
export const Signin = () => import('./Signin.vue');
export const Signup = () => import('./Signup.vue');
export const Tags = () => import('./Tags.vue');
export const Users = () => import('./Users.vue');
export const Workspace = () => import('./Workspace.vue');
export const Playground = () => import('./Playground.vue');
export const RfqList = () => import('./RfqList.vue');
export const Debug = () => import('./Debug.vue');
export const DxfConversionTool = () => import('./DxfConversionTool.vue');
export const CutlistUtils = () => import('./CutlistUtils.vue');
export const PlatformConfig = () => import('./PlatformConfig.vue');
export const AcceptWithAuthCode = () => import('./AcceptWithAuthCode.vue');
export const PromoCodesAdmin = () => import('./PromoCodesAdmin.vue');
export const Checkout = () => import('./Checkout.vue');
export const Organisations = () => import('./Organisations.vue');
export const Organisation = () => import('./Organisation/index.vue');
export const AccountContactDetails = () =>
  import('./Account/ContactDetails.vue');
export const AccountSecurity = () => import('./Account/Security.vue');
export const AccountNotifications = () => import('./Account/Notifications.vue');
export const AccountAddresses = () => import('./Account/Addresses.vue');
export const AccountMembers = () => import('./Account/Members.vue');
export const AccountOrganisationDetails = () =>
  import('./Account/OrganisationDetails.vue');
export const AccountWrapper = () => import('./Account/AccountWrapper.vue');
export const TestData = () => import('./TestData.vue');
export const UserWrapper = () => import('./User/UserWrapper.vue');
export const AdminSettings = () => import('./User/AdminSettings.vue');
export const ContactDetails = () => import('./User/ContactDetails.vue');
export const HardwareAndAppliances = () =>
  import('./HardwareAndAppliances.vue');
