<!-- eslint-disable vue/no-bare-strings-in-template -->
<template>
  <section class="miniStack">
    <p>Collection of Vue Formulate inputs to use in forms.</p>
    <div class="miniStack">
      <FormulateForm name="bulk-edit-rows">
        <div>
          <h3>radio buttons</h3>
          <BaseCard>
            <FormulateInput
              type="radio"
              name="radio1"
              label="horizontal radio"
              inputClass="inline"
              :options="[
                { value: 'no', label: 'No' },
                { value: 'yes', label: 'Yes' },
              ]"
            />
            <FormulateInput
              type="radio"
              name="radio2"
              label="vertical radio"
              :options="[
                { value: 'no', label: 'No' },
                { value: 'yes', label: 'Yes' },
                { value: 'maybe', label: 'Maybe' },
              ]"
            />
          </BaseCard>
          <div />
        </div>

        <div class="buffer-top">
          <h3>Toggle</h3>
          <BaseCard>
            <FormulateInput
              type="toggle"
              name="toggle1"
              label="toggle switch"
            />

            <FormulateInput
              type="toggle"
              :disabled="true"
              name="toggle2"
              label="toggle switch (disabled)"
            />

            <FormulateInput
              type="toggle"
              :disabled="true"
              name="toggle3"
              label="toggle switch with lot of help"
              help="Esse nostrud dolore deserunt officia magna laboris qui laborum cupidatat elit nostrud reprehenderit culpa sit ad. Officia amet voluptate velit consectetur consectetur nulla ex. Esse nulla consectetur amet laboris. Ex anim minim anim eu officia aliqua consequat pariatur velit magna aute."
            />
          </BaseCard>
        </div>

        <div class="buffer-top">
          <h3>Quantity</h3>
          <BaseCard>
            <FormulateInput
              type="quantity"
              label="quantity"
              v-model="quantity"
            />
          </BaseCard>
        </div>
        <div class="buffer-top">
          <h3>Inputs</h3>
          <BaseCard>
            <FormulateInput type="text" label="Text" />
            <FormulateInput type="numeric" label="Numeric" />
            <FormulateInput type="amount" currency="eur" label="Amount" />
            <FormulateInput type="percentage" label="Percentage" />
            <FormulateInput
              type="datepicker"
              label="Date Picker"
              :disabledDates="{
                to: dayjs().startOf('day').add(4, 'weeks').toDate(),
              }"
            />
            <FormulateInput
              type="daysofweekpicker"
              label="Days of week Picker"
            />
            <FormulateInput type="textarea" label="TextArea" />
            <FormulateInput
              type="select"
              label="Select"
              :options="[
                { value: 'no', label: 'No' },
                { value: 'yes', label: 'Yes' },
                { value: 'maybe', label: 'Maybe' },
              ]"
              validation="required"
              :validation-messages="{
                required: $t('formulate:select.requiredMessage'),
                not: $t('formulate:select.requiredMessage'),
              }"
            />
            <FormulateInput
              type="vue-select"
              label="Vue Select"
              :options="[
                { value: 'no', label: 'No' },
                { value: 'yes', label: 'Yes' },
                { value: 'maybe', label: 'Maybe' },
              ]"
              validation="required"
              :validation-messages="{
                required: $t('formulate:select.requiredMessage'),
                not: $t('formulate:select.requiredMessage'),
              }"
            />
            <FormulateInput
              type="findandselect"
              label="Find and Select"
              :filteredOptions="[
                { value: 'no', label: 'No' },
                { value: 'yes', label: 'Yes' },
                { value: 'maybe', label: 'Maybe' },
              ]"
              validation="required"
              :validation-messages="{
                required: $t('formulate:select.requiredMessage'),
                not: $t('formulate:select.requiredMessage'),
              }"
            />
            <FormulateInput
              type="inlineselect"
              label="Inline Select"
              :options="[
                { value: 'no', label: 'No' },
                { value: 'yes', label: 'Yes' },
                { value: 'maybe', label: 'Maybe' },
              ]"
              validation="required"
              :validation-messages="{
                required: $t('formulate:select.requiredMessage'),
                not: $t('formulate:select.requiredMessage'),
              }"
            />
            <FormulateInput type="checkbox" label="Checkbox" />
            <FormulateInput
              type="checkbox"
              label="Simple Checkbox"
              class="simple-checkbox"
            />
            <FormulateInput
              type="file"
              name="file"
              label="Select your documents to upload"
              help="Select one or more PDFs to upload"
              validation="mime:application/pdf"
              multiple
            />
          </BaseCard>
        </div>
      </FormulateForm>
    </div>
  </section>
</template>
<script setup lang="ts">
import dayjs from 'dayjs';
import { watch } from 'vue';
import { ref } from 'vue';

const quantity = ref<number>(2);

watch(
  () => quantity.value,
  (newVal) => console.log(newVal)
);
</script>
