<!-- eslint-disable vue/no-bare-strings-in-template -- admin only -->

<template>
  <Datepicker
    :class="`formulate-input-element formulate-input-element--${context.type}`"
    :input-class="context.attributes.class"
    :data-type="context.type"
    v-model="date"
    v-bind="attributes"
    @blur="blurHandler"
  >
    <template #calendarBtn
      ><i class="material-icons icon">calendar_month</i></template
    >
    <template #clearBtn><i class="material-icons icon">cancel</i></template>
  </Datepicker>
</template>

<script>
/* eslint-disable vue/no-mutating-props */
import Datepicker from '@sum.cumo/vue-datepicker';
import dayjs from '../../helpers/dayjs';
import { FORMULATE_DATEPICKER_LOCALES } from '../../helpers/i18n-module-localisations.js';

const STORED_FORMAT = 'YYYY-MM-DD';
const DISPLAY_FORMAT = 'D MMM YYYY';
const SUPPORTED_TYPED_FORMATS = [DISPLAY_FORMAT, 'D-M-YYYY', 'D-M-YY', 'D-M'];

export default {
  props: {
    context: {
      type: Object,
      required: true,
    },
  },
  computed: {
    language() {
      return this.$i18next.resolvedLanguage || 'en';
    },

    date: {
      get() {
        if (this.context.model) {
          return this.formatDate(this.context.model);
        } else return null;
      },
      set(val) {
        this.context.rootEmit('input', this.formatDate(val));
        this.context.model = this.formatDate(val);
      },
    },
    attributes() {
      const attributes = this.context.attributes || {};

      let openDate = attributes['open-date'] || attributes['openDate'];
      const disabledDates =
        attributes['disabled-dates'] || attributes.disabledDates;
      if (!openDate && disabledDates?.to) {
        openDate = dayjs(disabledDates.to).add(1, 'day').toDate();
      }
      if (!openDate && disabledDates?.from) {
        openDate = dayjs(disabledDates.from).subtract(1, 'day').toDate();
      }

      return {
        language:
          FORMULATE_DATEPICKER_LOCALES[this.language] ||
          FORMULATE_DATEPICKER_LOCALES.en,
        'calendar-button': true,
        'first-day-of-week': 'mon',
        typeable: true,
        parser: (date) =>
          dayjs(date, SUPPORTED_TYPED_FORMATS, this.language).toDate(),
        format: this.formatForDisplay,
        placeholder: this.$i18next.t('formulate:datePicker.defaultPlaceholder'),
        openDate,
        ...attributes,
        'clear-button': attributes.disabled
          ? false
          : attributes['clear-button'] || attributes.clearButton,
      };
    },
  },
  watch: {
    'context.model': {
      handler(val) {
        this.context.model = this.formatDate(val);
        this.context.rootEmit('input', this.formatDate(val));
      },
    },
    'context.value': {
      handler(val) {
        this.context.model = this.formatDate(val);
        this.context.rootEmit('input', this.formatDate(val));
      },
    },
  },
  methods: {
    formatDate(date, format = STORED_FORMAT) {
      if (!date) return null;
      return dayjs(date).locale(this.language).format(format);
    },

    formatForDisplay() {
      const date = this.context.model;
      const formattedDate = dayjs(date)
        .locale(this.language)
        .format(DISPLAY_FORMAT);
      if (this.context.attributes.showWeekOfYear) {
        return `${formattedDate} (Week ${dayjs(date).week()})`;
      }
      return formattedDate;
    },

    blurHandler() {
      this.context.blurHandler();
      // shim blur event because DatePicker doesn't pass an event via @blur
      this.context.rootEmit('blur', {
        target: { name: this.context.attributes.name },
      });
    },
  },
  components: { Datepicker },
};
</script>
