<template v-else>
  <div>
    <h3>{{ $t('landing-page:project.cutlist.createCutlist') }}</h3>
    <ol :class="[$style.steps, 'buffer-top-lg']">
      <li>
        <strong>{{ $t('landing-page:project.cutlist.step1') }}</strong>
      </li>
      <li>
        <strong>{{ $t('landing-page:project.cutlist.step2') }}</strong>
      </li>
      <li>
        <strong>{{ $t('landing-page:project.cutlist.step3') }}</strong>
      </li>
    </ol>

    <div class="buffer-lg">
      <Button
        data-cy="btn-create-cutlist"
        class="signal"
        :on-click="onClick"
        :trackOverride="() => $track('page:launchpad:cutlist-quote', {})"
        style="max-width: 22rem"
        >{{ $t('landing-page:project.cutlist.button') }}</Button
      >
    </div>

    <IpNotice
      @click="
        () => $track('page:launchpad:privacy-policy', { quoteType: 'cutlist' })
      "
    />

    <hr />

    <h4>
      <strong>{{ $t('landing-page:howItWorks.title') }}</strong>
    </h4>
    <div class="buffer-top">
      <Button
        class="pill white fluid-width"
        icon="play_circle"
        :href="getLoomUrlCutlist($i18next.resolvedLanguage)"
        target="_blank"
        :iconBefore="true"
        :trackOverride="
          () => $track('page:launchpad:video-button', { quoteType: 'cutlist' })
        "
      >
        {{ $t('landing-page:howItWorks.video') }}
      </Button>
    </div>
  </div>
</template>
<script setup lang="ts">
import IpNotice from '@/components/common/IpNotice.vue';
import { useDraftOrder } from './composables';
import { useRouter } from '@/composables/plugins';

const router = useRouter();
const { order, variantId, beforeCreate } = useDraftOrder();

async function onClick() {
  await beforeCreate(true);
  await router.push({
    name: `order:cutlist`,
    params: { id: order.value?.id || '' },
    query: {
      variantId: variantId.value,
    },
  });
}

function getLoomUrlCutlist(lng?: string) {
  const id =
    lng === 'nl'
      ? 'f7c2f32a86b34791b7899f09dc73fa61'
      : 'a5a7f027cbcb489cac9824b9d7c5ff0b';

  return `https://www.loom.com/share/${id}`;
}
</script>
<style module>
.steps {
  margin: 0;
  padding: 0;
  counter-reset: item;
  list-style: none;
}

.steps > li {
  margin-top: 2rem;
  counter-increment: item;
}
.steps > li:before {
  content: counter(item);
  background: var(--gray-1);
  border-radius: 100%;
  color: #e98614;
  width: 1.2em;
  text-align: center;
  display: inline-block;
  margin-right: 0.25rem;
}
</style>
