import Vue from 'vue';
import VueRouter from 'vue-router';
import I18NextVue from 'i18next-vue';
import FloatingVue from 'floating-vue';
import Toast from 'vue-toastification';
import VueFormulate from '@braid/vue-formulate';
import * as Sentry from '@sentry/vue';

import { Fragment } from 'vue-frag';
import { createPinia, PiniaVuePlugin } from 'pinia';

import { liveUpdates } from '@/modules/live-updates';
import { useActiveUserStore } from '@/modules/store.js';
import { routes, routeKillSession, handleRoute } from '@/modules/routing.js';

import api from '@/helpers/api';
import i18next from '@/helpers/i18n';
import Tracker from '@/helpers/tracker';
import FormatPlugin from '@/helpers/format-plugin';
import ModalManager from '@/helpers/modal-manager';
import FeaturesPlugin from '@/helpers/features-plugin';
import HelpLinksPlugin from '@/helpers/help-links-plugin';

import { DestroyUserSession } from '@/helpers/session-helpers.js';
import { FORMULATE_LOCALES } from '@/helpers/i18n-module-localisations.js';
import {
  postalCodeValidation,
  errorHandler,
  naturalOrderValidation,
  phoneNumberValidation,
} from '@/helpers/formulate';

import { registerGlobalComponents } from './globalComponents';
import App from './App.vue';
import { watch } from 'vue';

Vue.use(Toast, {
  timeout: 2500,
  filterBeforeCreate(toast) {
    if (typeof toast.icon === 'string') {
      toast.icon = {
        iconClass: 'material-icons-outlined',
        iconChildren: toast.icon,
        iconTag: 'span',
      };
    }
    return toast;
  },
});
Vue.use(FloatingVue, {
  themes: {
    findandselect: {
      $extend: 'dropdown',
      triggers: [],
      autoHide: false,
      autoSize: 'min',
    },
    annotation: {
      $extend: 'dropdown',
      placement: 'right-start',
      distance: 16,
      skidding: -16,
    },
    annotationTooltip: {
      $extend: 'tooltip',
      placement: 'right',
    },
  },
});
Vue.use(I18NextVue, { i18next });
Vue.use(FormatPlugin);
Vue.use(FeaturesPlugin);
Vue.use(HelpLinksPlugin);
Vue.use(ModalManager);
Vue.use({
  install(Vue) {
    Object.defineProperties(Vue.prototype, {
      $api: {
        get() {
          return api;
        },
      },
      $setPageTitle: {
        get() {
          return (title) => {
            document.title = ['Cutr', title].filter(Boolean).join(' | ');
          };
        },
      },
    });
  },
});

registerGlobalComponents();
Vue.component('Fragment', Fragment);

Vue.use(VueFormulate, {
  errorHandler,
  validationNameStrategy: ['validationName', 'label', 'name', 'type'],
  plugins: [
    postalCodeValidation,
    naturalOrderValidation,
    phoneNumberValidation,
    ...FORMULATE_LOCALES,
  ],
  classes: {
    outer: (context, classes) => {
      const { attrs, validation = '' } = context;
      return classes.concat([
        attrs.disabled ? 'formulate-input--disabled' : '',
        validation && String(validation).includes('required')
          ? 'formulate-input--required'
          : '',
      ]);
    },
  },
  slotComponents: {
    label: 'CutrFormulateLabel',
    help: 'CutrFormulateHelp',
  },
  slotProps: {
    label: [
      'helpLink',
      'helpLinkTooltip',
      'helpTooltip',
      'helpTooltipIcon',
      'badge',
    ],
    help: ['helpBelowInput'],
  },
  library: {
    findandselect: {
      classification: 'text',
      component: 'FormulateFindAndSelectInput',
    },
    datepicker: {
      classification: 'text',
      component: 'FormulateDatePicker',
    },
    daysofweekpicker: {
      classification: 'text',
      component: 'FormulateDaysOfWeekPicker',
    },
    toggle: {
      classification: 'checkbox',
      component: 'FormulateToggle',
    },
    quantity: {
      classification: 'text',
      component: 'FormulateQuantity',
    },
    'vue-select': {
      classification: 'search',
      component: 'FormulateVueSelect',
    },
    numeric: {
      classification: 'text',
      component: 'FormulateNumeric',
    },
    amount: {
      classification: 'text',
      component: 'FormulateAmount',
    },
    percentage: {
      classification: 'text',
      component: 'FormulatePercentage',
    },
    inlineselect: {
      classification: 'select',
      component: 'FormulateInlineSelect',
    },
  },
});
const pinia = createPinia();

Vue.use(PiniaVuePlugin);
Vue.use(VueRouter);
const router = new VueRouter({
  mode: 'history',
  routes: routes, // short for `routes: routes`
  scrollBehavior(to, from, savedPosition) {
    if (to.hash) {
      return { selector: to.hash, behavior: 'smooth' };
    } else if (savedPosition) {
      return savedPosition;
    } else {
      return { x: 0, y: 0 };
    }
  },
});

Vue.use(Tracker, { router });

router.afterEach(() => {
  window.productFruits?.pageChanged?.();
});

router.beforeEach((to, from, next) => {
  const userStore = useActiveUserStore();

  // Some routes will end current session to ensure people get there: forgotpass, resetpass, signup.
  if (routeKillSession[to.name]) {
    return DestroyUserSession(userStore, () =>
      handleRoute(
        next,
        userStore.activeSessionType,
        userStore.activeSessionRole,
        to
      )
    );
  }

  if (userStore.userLoaded && userStore.activeSessionType !== 'Public')
    return handleRoute(
      next,
      userStore.activeSessionType,
      userStore.activeSessionRole,
      to
    );

  api
    .get('/v2/sessions')
    .then((response) => {
      const user = response.data.data;
      userStore.setActiveUser(user);
      return user;
    })
    .catch((e) => {
      if (e.response) {
        const statusCode = e.response.status;

        // reset store if it has an active user and server returns a 4xx error.
        if (userStore.activeUserId && statusCode >= 400 && statusCode <= 499) {
          userStore.reset();
        }
        return userStore.activeUser;
      }
      return undefined;
    })
    .finally(() => {
      handleRoute(
        next,
        userStore.activeSessionType,
        userStore.activeSessionRole,
        to
      );
    });
});

Vue.mixin({
  beforeRouteEnter(to, from, next) {
    next((vm) => {
      vm.$modal.close('any', { trigger: 'route-change' });
      const title = to.meta.title
        ? vm.$t(`order:tabTitle.${to.meta.title}`, to.meta.title)
        : null;
      vm.$setPageTitle(title);
    });
  },
});
const SENTRY_ACTIVE = import.meta.env.PROD;
const DSN =
  'https://b05ac55e0d214407b72f0ed5a4611c5d@o880313.ingest.sentry.io/5840439';
Sentry.init({
  Vue,
  release: import.meta.env.VITE_GIT_HASH,
  environment: import.meta.env.VITE_CUTR_ENV,
  traceComponents: true,
  dsn: DSN,
  enabled: SENTRY_ACTIVE,
  logErrors: import.meta.env.DEV,
  beforeSend(event, hint) {
    const error = hint.originalException;

    // Ignore linkedin tracker error
    if (error?.message?.includes('window.lintrk')) {
      return null;
    }

    return event;
  },
  integrations: [
    new Sentry.BrowserTracing({
      routingInstrumentation: Sentry.vueRouterInstrumentation(router),
      tracePropagationTargets: [
        'localhost',
        'http://local.cutr.com',
        'https://local.cutr.com',
        'https://apistaging.cutr.ai',
        'https://api.aws-staging.cutr.ai',
        'https://api.cutr.ai',
        'https://apistaging.cutr.com',
        'https://api.gcp-staging.cutr.com',
        'https://api.gcp-prod.cutr.com',
        'https://api.cutr.com',
      ],
      beforeNavigate: (context) => ({
        ...context,
        name: location.pathname
          .replace(/\/[a-f\d]{24}/gi, '/<mongoId>')
          .replace(/\/[0-9A-Z]{26}/gi, '/<ulid>'),
      }),
    }),
  ],
  tracesSampler: (samplingContext) => {
    // inherit parent
    if (samplingContext.parentSampled !== undefined) {
      return samplingContext.parentSampled;
    }
    return 0.25;
  },
});

Vue.config.productionTip = false;

new Vue({
  render: (h) => h(App),
  pinia,
  router,
}).$mount('#app');

const userStore = useActiveUserStore();
userStore.$subscribe((mutation, { user }) => {
  localStorage.setItem(mutation.storeId, JSON.stringify({ user }));
});

watch(
  () => userStore.isLoggedIn,
  (isLoggedIn) => {
    if (isLoggedIn) {
      liveUpdates.connect();
    } else {
      liveUpdates.disconnect();
    }
  },
  { immediate: true }
);
